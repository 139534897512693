<template>
    <div>
        <div :class="{'title': true, 'title-mobile': isMobile}">Оплата</div>

        <card-payment-info
            :point="1"
            heading="Заполнить корзину"
            description="Выберите интересующие Вас товары и добавьте их в корзину"
            :class-img="isMobile ? 'products_list_img_mobile' : 'products_list_img'"
            :is-mobile="isMobile"
            :show-line="!isMobile"
        ></card-payment-info>

        <card-payment-info
            :point="2"
            heading="Перейти к оформлению заказа"
            description="При оформлении заказа Вы можете выбрать наиболее удобный способ оплаты"
            :class-img="isMobile ? 'create_order_img_mobile' : 'create_order_img'"
            :is-mobile="isMobile"
            :show-line="!isMobile"
        ></card-payment-info>

        <card-payment-info
            :point="3"
            heading="Выбрать способ оплаты"
            :description="[
                'Оплата наличными. Вы можете оплатить заказ наличными курьеру. Либо наличными при получении заказа оформленного на самовывоз в одном из наших магазинов.',
                'Оплата банковской картой. Оплата происходит через авторизационный сервер Процессингового центра Банка с использованием Банковских кредитных карт следующих платежных систем:'
            ]"
            class-img="allowed_cards_img"
            :is-mobile="isMobile"
        ></card-payment-info>

        <div class="container" :class="{'p_mobile': isMobile}">
            <div class="card__heading" :class="{'card__heading_mobile': isMobile}">Процесс передачи данных</div>

            <p>Для оплаты покупки Вы будете перенаправлены на платежный шлюз ПАО Сбербанк для ввода реквизитов Вашей карты. Пожалуйста, приготовьте Вашу пластиковую карту заранее. Соединение с платежным шлюзом и передача информации осуществляется в защищенном режиме с использованием протокола шифрования SSL.</p>
            <p>В случае если Ваш банк поддерживает технологию безопасного проведения интернет-платежей Verified By Visa или MasterCard Secure Code для проведения платежа также может потребоваться ввод специального пароля. Способы и возможность получения паролей для совершения интернет-платежей Вы можете уточнить в банке, выпустившем карту.</p>
            <p>Настоящий сайт поддерживает 256-битное шифрование. Конфиденциальность сообщаемой персональной информации обеспечивается ПАО Сбербанк. Введенная информация не будет предоставлена третьим лицам за исключением случаев, предусмотренных законодательством РФ. Проведение платежей по банковским картам осуществляется в строгом соответствии с требованиями платежных систем Visa Int. и MasterCard Europe Sprl.</p>
            <p>При возникновении проблем с оплатой заказа с помощью SberPay на странице банка, Покупатель можете связаться с ПАО «СБЕРБАНК РОССИИ» по телефону: 8 (800) 555-55-50. При возникновении проблем с оплатой заказа на странице банка с помощью банковской карты, сохраненной банковской карты, Покупатель можете связаться с CloudPayments по телефону +7 (495) 374-78-60.</p>
            <p>
                Список франчайзи которые принимают онлайн оплату через ПАО «Сбербанк»:
                <br>
                <br>ООО «Домик» - ИНН 5506142048
                <br>ИП Лунган И.В. - ИНН 554001000180
            </p>
        </div>
    </div>
</template>

<script>
import CardPaymentInfo from "./CardPaymentInfo.vue";

export default {
    name: 'PaymentInfo',
    components: {CardPaymentInfo},
    computed: {
        isMobile() {
            return window.innerWidth <= 700
        }
    }
}
</script>

<style lang="scss" scoped>
.title {
    color: #333;
    font-family: Roboto, sans-serif;
    font-size: 48px;
    font-style: normal;
    font-weight: 500;
    margin-bottom: 40px;

    &-mobile {
        font-size: 32px;
        font-weight: 500;
    }
}

.p_mobile > p {
    font-size: 16px;
    line-height: 24px !important;
}
.container > p {
    line-height: 30px;
}
</style>
